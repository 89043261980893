// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  //
  // Colors
  //
  --ion-color-primary: #3a5674;
  --ion-color-primary-rgb: 58, 86, 116;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #334c66;
  --ion-color-primary-tint: #4e6782;

  --ion-color-secondary: #f6a800;
  --ion-color-secondary-rgb: 246, 168, 0;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #d89400;
  --ion-color-secondary-tint: #f7b11a;

  --ion-color-tertiary: #75889d;
  --ion-color-tertiary-rgb: 117, 136, 157;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #67788a;
  --ion-color-tertiary-tint: #8394a7;

  --ion-color-success: #69bb7b;
  --ion-color-success-rgb: 105, 187, 123;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #5ca56c;
  --ion-color-success-tint: #78c288;

  --ion-color-warning: #f6a800;
  --ion-color-warning-rgb: 246, 168, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d89400;
  --ion-color-warning-tint: #f7b11a;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222222;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e1e1e;
  --ion-color-dark-tint: #383838;

  --ion-color-medium: #666666;
  --ion-color-medium-rgb: 102, 102, 102;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #5a5a5a;
  --ion-color-medium-tint: #757575;

  --ion-color-light: #f0f0f0;
  --ion-color-light-rgb: 240, 240, 240;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d3d3d3;
  --ion-color-light-tint: #f2f2f2;

  //
  // Typography
  //
  --ion-font-family: 'Alte DIN 1451 Mittelschrift';
  --ion-padding: 16px; // HACK: Workaround missing variables
  --ion-margin: 16px;

  //
  // Theme
  //
  --ion-text-color: #333333;
  --ion-text-color-rgb: 51, 51, 51;

  --ion-toolbar-background: var(--ion-color-tertiary);
  --ion-toolbar-color: var(--ion-color-primary-contrast);

  --ion-tab-bar-background: var(--ion-color-light);
  --ion-tab-bar-color: var(--ion-color-primary);
  --ion-tab-bar-color-selected: var(--ion-color-secondary);

  --ion-item-border-color: var(--ion-color-primary);
  --ion-item-color: var(--ion-color-primary);

  // BREAKING: These colors from Ionic v3 do not have a matching equivalent in this
  // version of Ionic:
  // - list-text-color
  // - list-border-color
}

// NOTE: No dark theme for now.
