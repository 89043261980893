@font-face {
  font-family: 'Alte DIN 1451 Mittelschrift';
  src: url('./fonts/din1451alt.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Alte DIN 1451 Mittelschrift';
  src: url('./fonts/din1451alt-G.ttf');
  font-weight: 700;
}

body {
  font-weight: 400;
  background-color: #fff;
}

// Simulate mobile device if device is likely a desktop. Note everything wider
// than iPad Pro is considered a desktop browser.
@media (min-device-width: 1025px) and (orientation: portrait),
  (min-device-width: 1367px) and (orientation: landscape) {
  body {
    background-color: #f8f9f9;
  }

  #root {
    // Pixel 2 XL
    > ion-app {
      width: 411px;
      height: 823px;
      margin: auto;
    }
  }

  // Workaround popover being positioned off-view
  .user-popover {
    &::part(content) {
      position: static;
    }

    ion-item {
      cursor: pointer;
    }
  }
}

.tab-has-icon {
  --padding-start: 6px;
  --padding-end: 6px;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.field-error {
  color: var(--ion-color-danger);
}

.form-error {
  margin-top: calc(var(--ion-margin) * 2);
}

.header-logo {
  height: 40px;
  padding: 5px;
}

ion-button {
  &.primary {
    --background: var(--ion-color-tertiary);
    --background-activated: var(--ion-color-tertiary-tint);
    --background-hover: var(--ion-color-tertiary-tint);
    --color: #fff;
  }

  &.secondary {
    --border-color: var(--ion-color-tertiary);
    --background-activated: var(--ion-color-tertiary-tint);
  }

  &.disabled {
    opacity: 0.7;
  }
}

.header-logo {
  height: 40px;
  padding: 5px;
}

.toolbar-background {
  border-bottom: 0;
}

.user-popover {
  .popover-content {
    min-width: 250px;
  }
}

ion-content {
  line-height: 1.4;

  strong {
    color: var(--ion-color-primary);
  }

  h1,
  h2,
  h3 {
    text-transform: uppercase;
  }

  h1 {
    font-size: 18px;
  }

  h1,
  h2 {
    color: var(--ion-color-primary);
  }

  h2 {
    font-size: inherit;
    margin: 0;
    letter-spacing: 0.5px;
  }

  h3 {
    font-size: inherit;
    color: var(--ion-color-dark);
    letter-spacing: 0.5px;
  }

  a {
    color: var(--ion-color-tertiary);
  }

  form {
    .errors {
      padding-top: 10px;
    }
  }

  .empty {
    text-align: center;
  }

  .table {
    width: 100%;
    line-height: 1.8;

    &.col-2 {
      td.label {
        position: relative;
        top: 1px;
        width: 40%;
        text-transform: uppercase;
        color: var(--ion-color-primary);
      }

      td:not(.label) {
        font-weight: 500;
        width: 60%;
      }
    }
  }

  .broadcast {
    $bg-color: var(--ion-color-secondary);
    $fg-color: #fff;
    padding: 10px;
    background-color: $bg-color;
    // TODO
    // border-bottom: 1px solid darken($bg-color, 20%);
    color: $fg-color;

    h2 {
      margin: 5px 0;
      color: inherit;
    }

    .description {
      font-size: 12px;
    }
  }

  .alternate-action {
    display: block;
    text-align: center;
    text-decoration: none;
    color: var(--ion-color-primary);
    font-size: 12px;
    padding: 10px 0;
  }
}

ion-tabs {
  .tab-button-text {
    font-size: 9px;

    @media (min-width: 375px) {
      font-size: 10px;
    }
  }
}

ion-item::part(detail-icon) {
  --detail-icon-color: var(--ion-color-primary);
  --detail-icon-opacity: 1;
}

// https://golb.hplar.ch/2018/01/Custom-SVG-icons-in-Ionic.html
ion-icon {
  &[class*='custom-'] {
    mask-size: contain;
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    background: currentColor;
    width: 1em;
    height: 1em;
  }

  &[class*='custom-mobilepay'] {
    mask-image: url('./icon/mobilepay.svg');
  }
}

ion-popover {
  &::part(content) {
    min-width: 250px;
  }
}
